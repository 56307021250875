import React from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import { H3 } from '../components/common/H.style';
import DepartmentSchedule from '../components/DepartmentSchedule';
import DoctorInCharge from '../components/DoctorInCharge';
import SymptomExamples from '../components/SymptomExamples';
import ListItem from '../components/ListItem';

import cir from '../assets/img/cir.png';

const Cardio = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="循環器内科">
      <Img src={cir} />

      <H3 size={size}>当院でご利用いただける循環器内科関連検査</H3>
      <Flex
        center
        css={`
          margin: 20px 5px;
        `}
      >
        <Flex column>
          <ListItem label="心電図" />
          <ListItem label="胸部レントゲン" />
          <ListItem label="心臓超音波検査（心エコー）" />
          <ListItem label="頸動脈超音波検査（頸動脈エコー）" />
          <ListItem label="２４時間心電図（ホルター心電図）" />
          <ListItem label="睡眠時無呼吸簡易検査（自宅検査用）" />
          <ListItem label="脈波検査（PWV、CAVI。血管年齢検査）" />
          <ListItem label="ラピチェックH-FABP（心筋梗塞の簡易検査）" />
          <ListItem label="白血球（WBC）、LDH、ASTなどの緊急血液検査 （心筋梗塞診断用）" />
          <ListItem label="ワーファリン管理のための出血時間（PT-INR）迅速検査" />
        </Flex>
      </Flex>
    </SubLayout>
  );
};

export default Cardio;
